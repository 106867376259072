import _ from 'underscore';
import React, { Children, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Navigation,
	Pagination,
	Lazy,
	EffectFade,
	Autoplay,
	Grid
} from 'swiper';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import cx from 'classnames';

import Icon from 'components/Icon';

import { SCREEN_BREAKPOINTS } from 'utils/helpers/constants';

import { PVI_NO_STYLES_CLASSNAME } from 'utils/helpers/inclusion';
import styles from './styles.module.scss';

const renderNavButton = (params) => (
	<div className={cx(styles.button, styles[params.type], params.className)}>
		<Icon
			icon={params.icon}
			className={cx(styles.icon, styles[params.icon], PVI_NO_STYLES_CLASSNAME)}
			useClassName={PVI_NO_STYLES_CLASSNAME}
		/>
	</div>
);

const Slider = ({
	params,
	theme,
	slideWidth,
	slideHeight,
	children,
	getSwiper,
	slidesPerView,
	className,
	darkColorTheme,
	lazy,
	leftButtonClassName,
	rightButtonClassName,
	renderPrevButton,
	renderNextButton,
	slideToNumber,
	alwaysShowNavigation
}) => {
	const [noSwiping, setNoSwiping] = useState(false);
	const [swiperRef, setSwiperRef] = useState(false);
	const [showFadeStartSlide, setShowFadeStartSlide] = useState(false);
	const [showFadeEndSlide, setShowFadeEndSlide] = useState(true);

	const calculateNoSwiping = useCallback((swiperInstance) => {
		if (
			swiperInstance &&
			!swiperInstance.destroyed &&
			swiperInstance.slides?.length &&
			swiperInstance.isBeginning
		) {
			const lastSlide = swiperInstance.slides[swiperInstance.slides.length - 1];

			let { slidesOffsetBefore, slidesOffsetAfter } = swiperInstance.params;

			if (typeof slidesOffsetBefore === 'function') {
				slidesOffsetBefore = slidesOffsetBefore();
			}

			if (typeof slidesOffsetAfter === 'function') {
				slidesOffsetAfter = slidesOffsetAfter();
			}

			const containerWidth =
				swiperInstance.$el?.outerWidth() -
				slidesOffsetBefore -
				slidesOffsetAfter;

			return lastSlide.offsetLeft + lastSlide.offsetWidth <= containerWidth + 5;
		}

		return false;
	}, []);

	const onInit = useCallback((swiperInstance) => {
		setSwiperRef(swiperInstance);
		getSwiper(swiperInstance);

		if (theme === 'tabs') {
			swiperInstance.on('transitionStart', () => {
				const currentPositionSlide = Math.round(
					swiperInstance.slides[swiperInstance.activeIndex].offsetLeft
				);
				const translatePositionSlider = Math.round(
					Math.abs(swiperInstance.translate)
				);
				const activeSlideNotOffEdge =
					translatePositionSlider - currentPositionSlide !== 0;

				setShowFadeStartSlide(!!activeSlideNotOffEdge);
				setShowFadeEndSlide(!swiperInstance.isEnd);
			});
		}

		swiperInstance.on('resize', () => {
			swiperInstance.update();

			if (!alwaysShowNavigation) {
				setNoSwiping(calculateNoSwiping(swiperInstance));
			}
		});
	}, []);

	useEffect(
		() => () => {
			if (swiperRef && !swiperRef.destroyed) {
				swiperRef.destroy();
			}
		},
		[]
	);

	useEffect(() => {
		if (!alwaysShowNavigation) {
			setNoSwiping(calculateNoSwiping(swiperRef));
		}
		if (params.autoHeight && swiperRef) {
			try {
				swiperRef?.updateAutoHeight(200);
				// eslint-disable-next-line no-empty
			} catch {}
		}
	}, [swiperRef, children]);

	useEffect(() => {
		if (swiperRef && typeof slideToNumber === 'number') {
			swiperRef.slideTo(slideToNumber);
		}
	}, [swiperRef, slideToNumber]);

	const sliderClass = cx(
		styles.slider,
		styles[`theme_${theme}`],
		styles[`slideWidth_${slideWidth}`],
		styles[`slideHeight_${slideHeight}`],
		{ [styles.darkColorTheme]: darkColorTheme },
		noSwiping && 'swiper-no-swiping',
		!swiperRef && styles.hidden,
		className
	);

	const swiperParams = {
		slidesPerView,
		spaceBetween: 8,
		updateOnWindowResize: false,
		observer: true,
		navigation: {
			nextEl: `.${styles.button}.${styles.right}`,
			prevEl: `.${styles.button}.${styles.left}`
		},
		noSwiping,
		breakpoints: {
			[SCREEN_BREAKPOINTS.mobileLarge]: {
				spaceBetween: 12
			},
			[SCREEN_BREAKPOINTS.tabletMedium]: {
				spaceBetween: 16
			},
			[SCREEN_BREAKPOINTS.tabletLarge]: {
				spaceBetween: 24
			}
		},
		onSwiper: onInit,
		...(lazy && { lazy: true, preloadImages: false }),
		...params
	};

	return (
		!_.isEmpty(children) && (
			<div className={sliderClass}>
				<Swiper
					{...swiperParams}
					modules={[Navigation, Pagination, Lazy, EffectFade, Autoplay, Grid]}
				>
					{Children.map(
						children,
						(child) =>
							child && <SwiperSlide key={child.key}>{child}</SwiperSlide>
					)}
					{swiperParams.navigation && !noSwiping && (
						<>
							{renderPrevButton
								? renderPrevButton()
								: renderNavButton({
										type: 'right',
										icon: 'chevronRightRounded',
										className: rightButtonClassName
								  })}
							{renderNextButton
								? renderNextButton()
								: renderNavButton({
										type: 'left',
										icon: 'chevronLeftRounded',
										className: leftButtonClassName
								  })}
						</>
					)}
				</Swiper>
				{theme === 'tabs' && showFadeEndSlide && (
					<div className={cx(styles.FadeSlides, styles.FadeSlides__right)} />
				)}
				{theme === 'tabs' && showFadeStartSlide && (
					<div className={cx(styles.FadeSlides, styles.FadeSlides__left)} />
				)}
			</div>
		)
	);
};

Slider.propTypes = {
	params: PropTypes.object,
	theme: PropTypes.oneOf([
		'detailed',
		'exhibits',
		'mobileMultirow',
		'simple',
		'full',
		'multirow',
		'tabs',
		'editorChoice',
		'entity'
	]),
	slideWidth: PropTypes.oneOf(['mobileFixedSize']),
	slideHeight: PropTypes.oneOf(['fill']),
	lazy: PropTypes.bool,
	getSwiper: PropTypes.func,
	slidesPerView: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	children: PropTypes.node,
	className: PropTypes.string,
	leftButtonClassName: PropTypes.string,
	rightButtonClassName: PropTypes.string,
	renderNextButton: PropTypes.func,
	renderPrevButton: PropTypes.func,
	leftButtonText: PropTypes.string,
	rightButtonText: PropTypes.string,
	darkColorTheme: PropTypes.bool,
	slideToNumber: PropTypes.number,
	alwaysShowNavigation: PropTypes.bool
};

Slider.defaultProps = {
	params: {},
	getSwiper: _.noop,
	lazy: false,
	slidesPerView: 'auto',
	darkColorTheme: false,
	alwaysShowNavigation: false
};

export default memo(Slider);
