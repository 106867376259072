import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import HeadTitle from 'components/HeadTitle';

import styles from './styles.module.scss';

const Section = ({
	children,
	title,
	subtitle,
	url,
	align,
	headClassName,
	theme,
	titleTheme,
	titleSize
}) => {
	const sectionHeadCx = cx(
		styles.Section__Head,
		styles[`Section__Head_align_${align}`],
		{
			[styles.Section__Head_spacing_medium]: titleSize === 'medium'
		},
		styles[`Section__Head_theme_${theme}`],
		headClassName
	);

	return (
		<section className={styles[`Section_theme_${theme}`]}>
			<div className={sectionHeadCx}>
				{title && (
					<HeadTitle
						text={title}
						align={align}
						url={url}
						theme={titleTheme}
						size={titleSize}
					/>
				)}
				{subtitle && <div className={styles.Section__Subtitle}>{subtitle}</div>}
			</div>
			{children}
		</section>
	);
};

Section.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	url: PropTypes.string,
	align: PropTypes.oneOf(['center']),
	headClassName: PropTypes.string,
	titleTheme: PropTypes.string,
	theme: PropTypes.oneOf(['entity', 'similar']),
	titleSize: PropTypes.oneOf(['medium'])
};

export default Section;
