import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'components/Icon';

import styles from './styles.module.scss';
// @TODO: theme newDesign maked default theme (when apply changes for all page)
const HeadTitle = ({ text, url, align, theme, size, className }) => {
	const headTitleCx = cx(
		styles.HeadTitle,
		styles[`HeadTitle_size_${size}`],
		styles[`HeadTitle_align_${align}`],
		styles[`HeadTitle_theme_${theme}`],
		className
	);

	return (
		<div className={headTitleCx}>
			{url ? (
				<a href={url} className={styles.HeadTitle__Link}>
					{text}
					<Icon icon="chevronRight" className={styles.HeadTitle__Icon} />
				</a>
			) : (
				<span className={styles.HeadTitle__Text}>{text}</span>
			)}
		</div>
	);
};

HeadTitle.propTypes = {
	text: PropTypes.string.isRequired,
	url: PropTypes.string,
	theme: PropTypes.oneOf(['live']),
	align: PropTypes.oneOf(['center']),
	size: PropTypes.oneOf(['medium', 'default', 'large']),
	className: PropTypes.string
};

HeadTitle.defaultProps = {
	size: 'default'
};

export default HeadTitle;
