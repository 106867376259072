import _ from 'underscore';
import React, { Children, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SimilarEntities from 'components/sections/SimilarEntities';
import EventCard from 'components/cards/entities/EventCard';
import { EventType } from 'propTypes/entities';
import { useBreakpoint } from 'hooks/useBreakpoint';
import styles from './styles/main.module.scss';

const Grid = ({
	colDefault,
	colMobileSmall,
	colMobileMedium,
	colMobileIphoneXs,
	colMobileLarge,
	colTabletSmall,
	colTabletMedium,
	colTabletLarge,
	colNotebook,
	colDesktopSmall,
	colDesktopMedium,
	space,
	gutter,
	overflow,
	align,
	flexibleWidth,
	children,
	className,
	innerSliderItems
}) => {
	const breakpoint = useBreakpoint();

	const gridCx = cx(
		styles.grid,
		styles[`default_${colDefault}`],
		styles[`mobileSmall_${colMobileSmall}`],
		styles[`mobileMedium_${colMobileMedium}`],
		styles[`mobileIphoneXs_${colMobileIphoneXs}`],
		styles[`mobileLarge_${colMobileLarge}`],
		styles[`tabletSmall_${colTabletSmall}`],
		styles[`tabletMedium_${colTabletMedium}`],
		styles[`tabletLarge_${colTabletLarge}`],
		styles[`notebook_${colNotebook}`],
		styles[`desktopSmall_${colDesktopSmall}`],
		styles[`desktopMedium_${colDesktopMedium}`],
		styles[`gutter_${gutter}`],
		styles[`space_${space}`],
		styles[`align_${align}`],
		{
			[styles.flexibleWidth]: flexibleWidth,
			[styles.overflow]: overflow
		},
		className
	);

	const breakpointColsHash = useMemo(() => {
		const mobileSmall = colMobileSmall || colDefault;
		const iphoneXs = colMobileIphoneXs || mobileSmall;
		const mobileMedium = colMobileMedium || iphoneXs;
		const mobileLarge = colMobileLarge || mobileMedium;
		const tabletSmall = colTabletSmall || mobileLarge;
		const tabletMedium = colTabletMedium || tabletSmall;
		const tabletLarge = colTabletLarge || tabletMedium;
		const notebook = colNotebook || tabletLarge;
		const desktopSmall = colDesktopSmall || notebook;
		const desktopMedium = colDesktopMedium || desktopSmall;
		const desktopLarge = desktopMedium;
		return {
			mobileSmall,
			iphoneXs,
			mobileMedium,
			mobileLarge,
			tabletSmall,
			tabletMedium,
			tabletLarge,
			notebook,
			desktopSmall,
			desktopMedium,
			desktopLarge
		};
	}, [
		colDefault,
		colMobileSmall,
		colMobileMedium,
		colMobileIphoneXs,
		colMobileLarge,
		colTabletSmall,
		colTabletMedium,
		colTabletLarge,
		colNotebook,
		colDesktopSmall,
		colDesktopMedium
	]);

	if (_.isEmpty(innerSliderItems)) {
		return (
			<div className={gridCx}>
				<div className={styles.inner}>
					{Children.map(
						children,
						(block) =>
							!_(block).isEmpty() && (
								<div className={styles.col} key={block.key}>
									{block}
								</div>
							)
					)}
				</div>
			</div>
		);
	}

	const cols = breakpointColsHash[breakpoint];
	const rowsToSkip = 2;
	const cardsToSkip = cols * rowsToSkip;

	const childrenArray = Children.toArray(children);
	const firstChildrenPart = childrenArray.slice(0, cardsToSkip);
	const secondChildrenPart = childrenArray.slice(
		cardsToSkip,
		childrenArray.length
	);

	return (
		<div className={gridCx}>
			<div className={styles.inner}>
				{firstChildrenPart.map(
					(block) =>
						!_(block).isEmpty() && (
							<div className={styles.col} key={block.key}>
								{block}
							</div>
						)
				)}
			</div>
			{!_(innerSliderItems).isEmpty() && innerSliderItems?.length > 3 && (
				<div className={styles.recommendations}>
					<SimilarEntities
						Card={EventCard}
						cardParams={{ typeWidth: 'fluidLandscapeSlide' }}
						entities={innerSliderItems}
						title="Персональные рекомендации"
					/>
				</div>
			)}
			{!_(secondChildrenPart).isEmpty() && (
				<div className={styles.inner}>
					{secondChildrenPart.map(
						(block) =>
							!_(block).isEmpty() && (
								<div className={styles.col} key={block.key}>
									{block}
								</div>
							)
					)}
				</div>
			)}
		</div>
	);
};

Grid.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired,
	colDefault: PropTypes.number,
	colMobileSmall: PropTypes.number,
	colMobileMedium: PropTypes.number,
	colMobileIphoneXs: PropTypes.number,
	colMobileLarge: PropTypes.number,
	colTabletSmall: PropTypes.number,
	colTabletMedium: PropTypes.number,
	colTabletLarge: PropTypes.number,
	colNotebook: PropTypes.number,
	colDesktopSmall: PropTypes.number,
	colDesktopMedium: PropTypes.number,
	space: PropTypes.oneOf(['remove']),
	gutter: PropTypes.oneOf(['verticalLarge', 'middle']),
	align: PropTypes.oneOf(['spaceBetween']),
	flexibleWidth: PropTypes.bool,
	overflow: PropTypes.bool,
	className: PropTypes.string,
	innerSliderItems: PropTypes.arrayOf(EventType)
};

Grid.defaultProps = {
	space: 'remove',
	flexibleWidth: false,
	overflow: false
};

export default Grid;
