import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.module.scss';

const Container = ({
	children,
	className,
	innerClassName,
	width,
	gutter,
	noPrint,
	...props
}) => {
	const containerCx = cx(
		'container',
		{
			[styles.noPrint]: noPrint
		},
		className
	);

	const containerInnerCx = cx(
		styles[`width_${width}`],
		styles[`gutter_${gutter}`],
		innerClassName
	);

	return (
		<div className={containerCx} {...props} data-cy="container">
			<div className={containerInnerCx} data-cy="container-inner">
				{children}
			</div>
		</div>
	);
};

Container.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	innerClassName: PropTypes.string,
	width: PropTypes.oneOf([
		'default',
		'entity',
		'entityStyledContent',
		'entitySlider',
		'wideSlider',
		'landingSlider'
	]),
	gutter: PropTypes.oneOf(['adaptiveMobile', 'noMobile']),
	noPrint: PropTypes.bool
};

Container.defaultProps = {
	width: 'default'
};

export default Container;
