import { useEffect, useState } from 'react';
import { SCREEN_BREAKPOINTS } from '../utils/helpers/constants';

export const breakpointsKeys = Object.keys(SCREEN_BREAKPOINTS);

const getBreakpointNameBySize = (size) => {
	const filteredBreakpoints = breakpointsKeys.filter(
		(key) => SCREEN_BREAKPOINTS[key] <= size
	);

	return filteredBreakpoints[filteredBreakpoints.length - 1] || 'mobileSmall';
};

export const useBreakpoint = () => {
	const [breakpointName, setBreakpointName] = useState('');

	useEffect(() => {
		setBreakpointName(getBreakpointNameBySize(window.innerWidth));

		const onResize = () => {
			setBreakpointName(getBreakpointNameBySize(window.innerWidth));
		};

		window.addEventListener('resize', onResize);

		return () => window.removeEventListener('resize', onResize);
	}, []);

	return breakpointName;
};
