export const publicationTypeHash = {
	articles: 'Публикация',
	news: 'Новость',
	themes: 'Подборка',
	onkn: 'Традиции',
	rh: 'Традиции',
	fh: 'Традиции',
	hc: 'Традиции'
};

export const entitiesPrintTitleHash = {
	poems: 'стихотворение'
};

export const cardIcons = {
	accessible: {
		icon: 'accessible-new',
		title: 'Доступная среда'
	},
	podcasts: {
		icon: 'microphone',
		title: 'Подкасты'
	},
	pushkin: {
		icon: 'pushkin',
		title: 'Пушкинская карта'
	},
	tifloComments: {
		icon: 'audio-definition',
		title: 'Тифлокомментарии'
	},
	virtualTour: {
		icon: '360',
		title: 'Виртуальный тур'
	},
	liveBroadcast: {
		icon: 'broadcast',
		title: 'В эфире'
	},
	liveRecord: {
		icon: 'record',
		title: 'Запись'
	}
};

export const imageCardSizeDefault = {
	landscape: {
		width: 392,
		height: 281
	},
	portrait: {
		width: 214,
		height: 288
	}
};

export const cardCropTextDefault = {
	title: '3',
	description: '3'
};
